import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

interface MetaTags {
   description?: string;
}

interface Props {
   title: string;
   replaceTitle?: boolean;
   children: React.ReactNode;
   meta?: MetaTags;
}

const BlankPageWithoutNavigation = ({ title, children, meta, replaceTitle = false }: Props) => {
   //todo:update canonical url
   return (
      <HelmetProvider>
         <Helmet>
            <meta charSet="utf-8" />
            <title>2Africa Hub {replaceTitle ? title : ` | ${title}`}</title>
            <link rel="canonical" href="https://wiocc.net" />
            <meta name="description" content={meta?.description ?? '2Africa TT OSS'} />
         </Helmet>
         {children}
      </HelmetProvider>
   );
};

export default BlankPageWithoutNavigation;
