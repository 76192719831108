const colorPallete = {
  transparent: 'transparent',
  black: {
    50: '#eaf8fb',
    100: '#cee1e4',
    200: '#b0cbd0',
    300: '#91b5bd',
    400: '#729daa',
    500: '#588290',
    600: '#446370',
    700: '#304650',
    800: '#1b2a31',
    900: '#021014',
  },
  white: '#fff',
  primary: {
    //Themera
    // '50': '#E7F5FE',
    // '100': '#BBE2FB',
    // '200': '#90D0F9',
    // '300': '#64BEF7',
    // '400': '#38ABF5',
    // '500': '#0D99F2',
    // '600': '#0A7AC2',
    // '700': '#085C91',
    // '800': '#053D61',
    // '900': '#031F30',

    //smart-swatch
    50: '#ddf7ff',
    100: '#b1e1ff',
    200: '#84ccfa',
    300: '#55b8f6',
    400: '#2ba3f3',
    500: '#168ad9',
    600: '#096baa',
    700: '#004c7a',
    800: '#002e4c',
    900: '#00101e',
  },
  secondary: {
    50: '#fff8e0',
    100: '#feeab3',
    200: '#fcdb84',
    300: '#fccd54',
    400: '#fbbf28',
    500: '#e2a516',
    600: '#af800e',
    700: '#7e5c07',
    800: '#4c3701',
    900: '#1b1200',
  },
  gray: {
    50: '#f7fafc',
    // ...
    900: '#171923',
  },
};

export default colorPallete;
