import React from 'react';
import Loading from 'components/common/pages/Loading';
import { Navigate, Outlet, useLocation } from 'react-router';
import useAuth from '../useAuth';
import { userCanAccessRoute } from './route-util';
import NoPermissionError from './no-permission-error';

const PrivateRoute = () => {
   const { auth } = useAuth();
   const location = useLocation();

   if (!auth.isLoggedIn && !auth.isFetchingUserDetails) {
      const inLogout = location.pathname.includes('logout');
      const pathname = inLogout ? '' : location.pathname + location.search ?? '';
      const accessed_location = pathname ? `?accessed_location=${pathname}` : '';
      return <Navigate to={`/login${accessed_location}`} />;
   }

   if (auth.isFetchingUserDetails) {
      return <Loading />;
   }

   const userHasRoutePermission = userCanAccessRoute(
      location.pathname,
      auth.user?.permissions as string[]
   );

   if (!userHasRoutePermission) return <NoPermissionError />;

   return <Outlet />;
};

export default PrivateRoute;
