import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const Loading = () => {
   return (
      <div
         style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}
      >
         <ScaleLoader color="rgb(10,120,190)" />
      </div>
   );
};

export default Loading;
