import React from 'react';
import BlankPageWithoutNavigation from './BlankPageWithoutNavigation';
import { Stack, Box, Button, Heading } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const PageNotFound = () => {
   return (
      <BlankPageWithoutNavigation title="Page Not Found">
         <Box height="100%">
            <Stack direction="column" justifyContent="center" alignItems="center" height="100%">
               <Heading fontWeight="bold">Page Not Found</Heading>
               <Button as={RouterLink} to="/">
                  Go Home
               </Button>
            </Stack>
         </Box>
      </BlankPageWithoutNavigation>
   );
};

export default PageNotFound;
