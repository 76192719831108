import { useColorMode } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import type { PaletteMode } from '@mui/material';
import { createTheme, ThemeProvider, THEME_ID } from '@mui/material/styles';
import React from 'react';
import colorPallete from './color-palette';
// import shadows, { Shadows } from '@mui/material/styles/shadows';

export const MUI_THEME_IDENTIFIER = THEME_ID;

export const getMuiTheme = (colorMode: PaletteMode) => {
   return createTheme({
      palette: {
         mode: colorMode,
         primary: {
            main: colorPallete.primary[500],
         },
         secondary: {
            main: colorPallete.secondary[500],
         },
      },
      components: {
         MuiButton: {
            defaultProps: {
               sx: {
                  minWidth: 'auto',
                  textTransform: 'none',
                  fontWeight: 'bold',
               },
            },
         },
      },
      // shadows: shadows.map(() => '1') as Shadows,
   });
};

const muiCache = createCache({
   key: 'mui',
   prepend: true,
});

const CustomMuiThemeProvider = ({ children }: { children: React.ReactNode }) => {
   const { colorMode } = useColorMode();

   return (
      <CacheProvider value={muiCache}>
         <ThemeProvider theme={getMuiTheme(colorMode)}>{children}</ThemeProvider>
      </CacheProvider>
   );
};

export default CustomMuiThemeProvider;
