import type { AxiosRequestConfig, Method } from 'axios';
import axios, { AxiosError } from 'axios';
import { HttpStatusCodes } from './util';

export const SEVEN_MINUTES_CACHE_TIME = 1000 * 60 * 7;

export const DEFAULT_HTTP_TIMEOUT = SEVEN_MINUTES_CACHE_TIME; // 35000;
export const apiBaseURL =
   process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_PROD_API_URL
      : process.env.REACT_APP_DEV_API_URL;

const axiosInstance = axios.create({
   baseURL: apiBaseURL,
   timeout: DEFAULT_HTTP_TIMEOUT,
   headers: {
      common: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
      },
   },
   withCredentials: true,
});
export interface GetProps {
   url: string;
}

export type DefaultRecord = Record<string, unknown> | unknown;
export interface PostProps<Data extends DefaultRecord> extends GetProps {
   data?: Data;
   timeout?: number;
   onUploadProgress?: AxiosRequestConfig<any>['onUploadProgress'];
}

export interface HttpConfig extends PostProps<DefaultRecord> {
   method: Method;
}

const ACCESS_TOKEN = 'csrf_access_token=';
export const getAccessToken = () => {
   if (!document.cookie) return null;
   return (
      document.cookie
         ?.split('; ')
         .find((row) => row.startsWith(ACCESS_TOKEN))
         ?.split('=')[1] ?? null
   );
};
export const clearAccessToken = () => {
   document.cookie = `${ACCESS_TOKEN};expires=` + new Date(0).toUTCString();
};
const makeHttpCall = async (props: HttpConfig) => {
   try {
      const axiosRequestConfig: AxiosRequestConfig = props;
      if (document.cookie) {
         if (!axiosRequestConfig.headers) axiosRequestConfig.headers = {};
         axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = getAccessToken();
      }
      return (await axiosInstance.request(axiosRequestConfig)).data;
      // return data.data;
   } catch (e: unknown) {
      const error = e as AxiosError;
      const status = error.response?.status;

      // redirect users on signature expiry
      if (status === HttpStatusCodes.HTTP_401_UNAUTHORIZED) {
         clearAccessToken();
         sessionStorage.clear();

         if (!window.location.href.includes('/login')) {
            return (window.location.href = `${window.location.origin}/login`);
         }
      }

      return e;
   }
};

export const getRequest = <T>({ url }: GetProps): Promise<T> => {
   return makeHttpCall({ url, method: 'GET' });
};

export const postRequest = <T, Data extends DefaultRecord = DefaultRecord>(
   props: PostProps<Data>
): Promise<T> => {
   return makeHttpCall({ ...props, method: 'POST' });
};

export type HttpMethod = Method;
