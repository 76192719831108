import { ColorMode } from '@chakra-ui/react';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type ThemeState = {
   colorMode: ColorMode;
};

const getInitialThemeState = () => {
   const initialState: ThemeState = {
      colorMode: (localStorage.getItem('chakra-ui-color-mode') ?? 'light') as ColorMode,
   };

   return initialState;
};

export const themeSlice = createSlice({
   name: 'theme',
   initialState: getInitialThemeState(),
   reducers: {
      toggleColorMode: (state, action: PayloadAction<ThemeState['colorMode']>) => {
         state.colorMode = action.payload;
      },
   },
});

export const { toggleColorMode } = themeSlice.actions;

export default themeSlice.reducer;
