export enum RecordTypes {
   TICKET = 'TICKET',
   USER = 'USER',
   USERS = 'USERS',
   ROLE = 'ROLE',
   DOCS = 'DOCUMENT',
   CHANGE_REQUEST = 'CHANGE_REQUEST',
   KNOWLEDGE_BASE = 'KNOWLEDGE_BASE',
   MAINTENANCE = 'MAINTENANCE',
   SYSTEM = 'SYSTEM',
   CABLE_CROSSING = 'CABLE_CROSSING',
   BLACKOUT_DATE = 'BLACKOUT_DATE',
}

export enum RecordPermissions {
   CREATE = 'CREATE',
   UPDATE = 'UPDATE',
   EDIT = 'EDIT',
   VIEW = 'VIEW',
   ADMIN = 'ADMIN',
   DELETE = 'DELETE',
   UPLOAD = 'UPLOAD',
   // READ = 'READ',
   ALL = 'ALL',
   DOWNLOAD = 'DOWNLOAD',
   SHAREPOINT = 'SHAREPOINT',

   //APPROVAL
   APPROVE_MA = 'APPROVE_MA',
   APPROVE_MAWG = 'APPROVE_MAWG',
   UPDATE_RPL = 'UPDATE_RPL',

   //ASSIGNMENT
   ASSIGNMENT = 'ASSIGNMENT',

   //USER MANAGEMENT - FPO
   ENTITY_ADMIN = 'ENTITY_ADMIN',
}

export type MultiRecordPermission = [RecordPermissions, RecordTypes];

export type PermissionFn = (
   action: RecordPermissions,
   record: RecordTypes,
   permissions: string[]
) => boolean;
