import { lazy } from 'react';
import { AppRoute } from './interfaces';
import { AdminRoutes } from './util';

// const AdminDashboard = lazy(() => import('components/admin/AdminDashboard'));
// const DocumentManagement = lazy(() => import('components/admin/pages/DocumentManagement'));
const Ticketing = lazy(() => import('pages/admin/Ticketing'));
const UserManagement = lazy(() => import('pages/admin/UserManagement'));
const RoleManagement = lazy(() => import('pages/admin/RoleManagement'));

const ADMIN_ROUTES: AppRoute[] = [
   // { path: '/admin', element: AdminDashboard },
   { path: AdminRoutes.TICKETING, element: Ticketing },
   { path: AdminRoutes.USERS, element: UserManagement },
   { path: AdminRoutes.ROLES, element: RoleManagement },
];

export default ADMIN_ROUTES;
