import { Button, Flex } from '@chakra-ui/react';
import React, { Component } from 'react';
import './error-boundary.scss';
import { postRequest } from 'http/index';

interface Props {
   children: React.ReactNode;
}
interface State {
   hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
   constructor(props: Props) {
      super(props);

      this.state = { hasError: false };
   }

   componentDidCatch(error: any, errorInfo: any) {
      // You can also log the error to an error reporting service

      //remove unnecessary https//hub../static/js/bundle.js etc values in payload
      const componentStack = errorInfo.componentStack.replace(/\([a-zA-Z\d:/_.]*\)/g, '');
      try {
         postRequest<any>({
            url: '../react-logs',
            data: { error: error.toString(), componentStack },
         });
      } catch (e) {
         //
      }
   }

   static getDerivedStateFromError() {
      return { hasError: true };
   }

   render() {
      if (this.state.hasError) {
         return (
            <Flex justify="center" align="center" height="100vh">
               <div>
                  <h1 style={{ fontSize: '1.8em' }}>Something went wrong</h1>
                  <div className="d-flex">
                     <Button
                        variant="solid"
                        className="error-boundary-btn"
                        onClick={() => window.location.reload()}
                     >
                        Reload
                     </Button>
                  </div>
               </div>
            </Flex>
         );
      }

      return this.props.children;
   }
}

export default ErrorBoundary;
