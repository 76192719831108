import ErrorBoundary from 'components/common/ErrorBoundary';
import React from 'react';
import AppChakraProvider from './AppChakraProvider';
import ReactQueryClientProvider from './ReactQueryClientProvider';
import ReduxProvider from './ReduxProvider';

interface Props {
   children: React.ReactNode;
}

const AppProviders = ({ children }: Props) => {
   return (
      <ErrorBoundary>
         <ReactQueryClientProvider>
            <ReduxProvider>
               <AppChakraProvider>{children}</AppChakraProvider>
            </ReduxProvider>
         </ReactQueryClientProvider>
      </ErrorBoundary>
   );
};

export default AppProviders;
