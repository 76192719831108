import { useEffect, useMemo, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { type User } from 'interfaces/auth';
import {
   fetchingUserDetails,
   login as loginAction,
   logout as logoutAction,
} from 'store/auth-slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { useQuery } from '@tanstack/react-query';
import { clearAccessToken, getAccessToken, getRequest } from 'http/index';

interface UserResponse {
   user: User;
}

const useAuth = (fetchUserInfo = true) => {
   const auth = useAppSelector((state) => state.auth, shallowEqual);
   const dispatch = useAppDispatch();

   const login = (user: User) => {
      if (!user) return;
      dispatch(loginAction(user));
   };

   const logout = () => {
      dispatch(logoutAction());
   };

   const hasCookies = useMemo(() => {
      const accessToken = getAccessToken();
      return accessToken !== undefined && accessToken !== null;
   }, []);

   const [hasError, setHasError] = useState(false);

   const { refetch, isError } = useQuery<UserResponse>({
      cacheTime: 0,
      enabled: false,
      retry: false,
      queryKey: ['user_details'],
      queryFn: () => {
         if (!hasCookies) return Promise.reject('Invalid request!');
         dispatch(fetchingUserDetails(true));
         return getRequest({ url: 'getUserDetails' });
      },
      onError: () => {
         dispatch(fetchingUserDetails(false));
         setHasError(true);
      },
      onSuccess: ({ user }) => {
         if (!user) {
            clearAccessToken();
            dispatch(fetchingUserDetails(false));
            return setHasError(true);
         }
         dispatch(fetchingUserDetails(false));
         login(user);
      },
   });

   useEffect(() => {
      if (auth.isFetchingUserDetails || auth.user || auth.isLoggedIn || hasError || isError) return;
      if (hasCookies && fetchUserInfo && !isError) refetch();
      if (!hasCookies && auth.isLoggedIn) logout();
   }, [auth.isFetchingUserDetails, auth.user, fetchUserInfo, auth.isLoggedIn, isError, hasError]);

   return { auth, login, logout, hasCookies, reloadUser: refetch };
};

export default useAuth;

export type { User };
