import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';

interface Props {
   preventRedirect?: boolean;
   record?: string;
}

const NoPermissionError = (props: Props) => {
   const { preventRedirect = true, record } = props;

   const [canRedirect, setCanRedirect] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      if (preventRedirect) return;
      setTimeout(() => {
         setCanRedirect(true);
      }, 5000);
   }, [preventRedirect]);

   if (canRedirect) {
      return <Navigate to="/" />;
   }

   return (
      <Box
         sx={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Alert
            status="warning"
            variant="subtle"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="220px"
            sx={{ maxW: ['98%', '55%'] }}
         >
            <AlertIcon boxSize="40px" mr={0} />
            <AlertTitle mt={4} mb={1} fontSize="lg">
               Permissions Error!
            </AlertTitle>
            <AlertDescription maxWidth="sm" sx={{ my: 1 }}>
               User missing permission to {record ? `view ${record}` : `access resource`}
               <br />
               Please contact system administrator.
            </AlertDescription>

            <Button
               sx={{ my: 3 }}
               onClick={() => {
                  navigate(-1);
               }}
            >
               Go Back
            </Button>
         </Alert>
      </Box>
   );
};

export default NoPermissionError;
