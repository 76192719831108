import { matchPath } from 'react-router';
import {
   AdminRoutes,
   CommonRoutes,
   DocumentRoutes,
   ProfileRoutes,
   RoutesWithPatterns,
   TicketRoutes,
} from 'routes/util';
import { MultiRecordPermission, PermissionFn, RecordPermissions, RecordTypes } from './record-util';

export const multiRecordPermissionInList = (
   actionRecords: MultiRecordPermission[],
   permissions: string[]
): boolean => {
   const permissionFound = actionRecords.find(([action, record]) => {
      return recordPermissionInList(action, record, permissions);
   });

   return permissionFound !== undefined;
};

const recordPermissionInList: PermissionFn = (permission, record, userPermissions) => {
   return userPermissions.includes(`${record}:${permission}`);
};

export const userCanAccessRoute = (mRoute: string, permissions?: string[]) => {
   let route = (mRoute.trim().startsWith('/') ? mRoute : `/${mRoute}`).toLowerCase();
   if (route !== '/' && route.endsWith('/')) {
      route = route.substring(0, route.length - 1);
   }

   if (route.startsWith('//')) {
      route = route.substring(1);
   }

   if (!permissions) return false;

   //SYSTEM ADMIN HAVE ACCESS TO ALL ROUTES
   if (recordPermissionInList(RecordPermissions.ADMIN, RecordTypes.SYSTEM, permissions)) {
      return true;
   }

   switch (route) {
      case TicketRoutes.ALL:
         return recordPermissionInList(RecordPermissions.VIEW, RecordTypes.TICKET, permissions);

      case TicketRoutes.NEW:
         return recordPermissionInList(RecordPermissions.CREATE, RecordTypes.TICKET, permissions);

      case AdminRoutes.TICKETING:
         return recordPermissionInList(RecordPermissions.ADMIN, RecordTypes.TICKET, permissions);

      case AdminRoutes.USERS: {
         const actionRecords: MultiRecordPermission[] = [
            [RecordPermissions.ADMIN, RecordTypes.USER],
            [RecordPermissions.ENTITY_ADMIN, RecordTypes.USER],
         ];
         return multiRecordPermissionInList(actionRecords, permissions);
      }

      case AdminRoutes.ROLES:
         return recordPermissionInList(RecordPermissions.ADMIN, RecordTypes.ROLE, permissions);

      case DocumentRoutes.ALL:
      case DocumentRoutes.DOCUMENT:
         return recordPermissionInList(RecordPermissions.VIEW, RecordTypes.DOCS, permissions);

      //CHANGE REQUEST
      case TicketRoutes.NEW_NETWORK_REQUEST:
         return recordPermissionInList(
            RecordPermissions.CREATE,
            RecordTypes.CHANGE_REQUEST,
            permissions
         );
      case TicketRoutes.ALL_NETWORK_REQUESTS:
         return recordPermissionInList(
            RecordPermissions.VIEW,
            RecordTypes.CHANGE_REQUEST,
            permissions
         );

      //CABLE CROSSING
      case TicketRoutes.NEW_CABLE_CROSSING:
         return recordPermissionInList(
            RecordPermissions.CREATE,
            RecordTypes.CABLE_CROSSING,
            permissions
         );
      case TicketRoutes.ALL_CABLE_CROSSINGS:
         return recordPermissionInList(
            RecordPermissions.VIEW,
            RecordTypes.CABLE_CROSSING,
            permissions
         );

      //ROUTINE MAINTENANCE
      case CommonRoutes.ROUTINE_MAINTENANCE:
         // return recordPermissionInList(
         //    RecordPermissions.CREATE,
         //    RecordTypes.MAINTENANCE,
         //    permissions
         // );
         return true;

      case CommonRoutes.ROUTINE_MAINTENANCE_REPORTS:
      case CommonRoutes.SUMMARY_ROUTINE_MAINTENANCE_REPORTS:
         // return recordPermissionInList(
         //    RecordPermissions.VIEW,
         //    RecordTypes.MAINTENANCE,
         //    permissions
         // );
         return true;

      case CommonRoutes.KNOWLEDGE_BASE:
         return recordPermissionInList(
            RecordPermissions.VIEW,
            RecordTypes.KNOWLEDGE_BASE,
            permissions
         );

      //TODO: permissions for partner routes

      case ProfileRoutes.HOME:
      case ProfileRoutes.ALL:
      case ProfileRoutes.LOGOUT:
      case CommonRoutes.USERS:
      case CommonRoutes.MAP:
         return true;

      default:
         return RoutesWithPatterns.find((pattern) => {
            if (matchPath(pattern, route)) {
               return userCanAccessRouteWithPattern(pattern, permissions);
            }

            return false;
         });
   }
};

export const userCanAccessRouteWithPattern = (routePattern: string, permissions: string[]) => {
   switch (routePattern) {
      case TicketRoutes.EDIT:
      case TicketRoutes.EDIT_WITH_ID:
         return recordPermissionInList(RecordPermissions.EDIT, RecordTypes.TICKET, permissions);

      case TicketRoutes.SINGLE_TICKET:
         // case PartnerRoutes.SINGLE_TICKET:
         return recordPermissionInList(RecordPermissions.VIEW, RecordTypes.TICKET, permissions);

      case TicketRoutes.SINGLE_NETWORK_REQUEST:
         // case PartnerRoutes.SINGLE_NETWORK_REQUEST:
         return recordPermissionInList(
            RecordPermissions.VIEW,
            RecordTypes.CHANGE_REQUEST,
            permissions
         );

      case TicketRoutes.EDIT_NETWORK_REQUEST:
         return recordPermissionInList(
            RecordPermissions.EDIT,
            RecordTypes.CHANGE_REQUEST,
            permissions
         );

      case CommonRoutes.SINGLE_KNOWLEDGE_BASE:
         return recordPermissionInList(
            RecordPermissions.VIEW,
            RecordTypes.KNOWLEDGE_BASE,
            permissions
         );

      case CommonRoutes.EDIT_SINGLE_KNOWLEDGE_BASE:
         return recordPermissionInList(
            RecordPermissions.EDIT,
            RecordTypes.KNOWLEDGE_BASE,
            permissions
         );

      case TicketRoutes.SINGLE_CABLE_CROSSING:
         return recordPermissionInList(
            RecordPermissions.VIEW,
            RecordTypes.CABLE_CROSSING,
            permissions
         );

      case TicketRoutes.EDIT_CABLE_CROSSING:
         return recordPermissionInList(
            RecordPermissions.EDIT,
            RecordTypes.CABLE_CROSSING,
            permissions
         );

      default:
         return false;
   }
};

export const hasPermission: PermissionFn = (permission, record, userPermissions) => {
   return recordPermissionInList(permission, record, userPermissions);
};
