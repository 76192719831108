import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import PrivateRoute from 'components/auth/private-route/PrivateRoute';
import Loading from 'components/common/pages/Loading';
import PageNotFound from 'components/common/pages/PageNotFound';

import ADMIN_ROUTES from 'routes/admin';
import APP_ROUTES from './routes';

const Login = React.lazy(() => import('pages/auth/Login'));

function App() {
   return (
      <BrowserRouter>
         <Suspense fallback={<Loading />}>
            <Routes>
               <Route path="/login" element={<Login />} />
               <Route element={<PrivateRoute />}>
                  {[...APP_ROUTES, ...ADMIN_ROUTES].map(
                     ({ path, element: Element }, i) => {
                        return <Route key={i} path={path} element={<Element />} />;
                     }
                  )}
               </Route>
               <Route path="*" element={<PageNotFound />} />
            </Routes>
         </Suspense>
      </BrowserRouter>
   );
}

export default App;
